
import { AppState } from "src/app-state";
import { User } from "src/app/model/user";
import { ChatMessageSocket } from "./chat-response";
import { MessageSocket } from "./messages-response";

export class MessageResponse {
    public text: string;
    public idUserFrom: string;
    public idUserTo: string;
    public deviceFrom: string;
    public created: string;
    public firstname: string;
    public surname: string;
    public gender: number;
    public avatar: string;
    public online: number;
    public msg_count: number;
    public age: number =  -1;
    public distance: number =  -1;

    
    public GetSocketMessage(): MessageSocket
    {
        const ret = new MessageSocket();
        ret.firstname = this.firstname;     
        ret.surname = this.surname;     
        ret.text = this.text;
        ret.avatar = this.avatar;
        ret.created = this.created;
        ret.id_user = AppState.loggedUser.id_hash == this.idUserTo ? this.idUserFrom : this.idUserTo;
        ret.id_user_from = this.idUserFrom;
        ret.gender = this.gender;
        ret.online = this.online;
        ret.newMessage = AppState.loggedUser.id_hash == this.idUserTo ? 1 : 0;
        ret.age = this.age;
        ret.distance = this.distance;
        return ret;
    }

    public GetChatSocketMessage(): ChatMessageSocket
    {
        const ret = new ChatMessageSocket();
        ret.id = Date.now();
        ret.text = this.text;
        ret.id_user_to = this.idUserTo;
        ret.id_user_from = this.idUserFrom;
        ret.created = this.created;
        ret.new = 1;
        return ret;
    }
}