
import { AppState } from 'src/app-state';
import { Request } from './request';

export class CreateUserRequest extends Request{
    public password: string = "";
    public password2: string = "";
    public email: string = "";
    public birthdate: string = "";
    public gender: number = -1;
    public phone: string = "";
    public surname: string = "";
    public firstname: string = "";
    public id_dial_code: number = -1;
    public id_lang = AppState.lang.getValue();
    public id_hash: string = null;
}
