import { AppState } from "src/app-state";

export class Request {
    public hash: string = "";
    public longitude: number = 0;
    public latitude: number = 0;
    public responseHash: string;

    constructor()
    {
        this.responseHash = this.generateUUID();
        if(AppState.loggedUser){
            this.longitude = AppState.loggedUser.longitude ? AppState.loggedUser.longitude : 0;
            this.latitude = AppState.loggedUser.latitude ? AppState.loggedUser.latitude : 0;
            if(AppState.enableGeoPosition.getValue()){
                const pos = AppState.geoPosition.getValue();
                if(pos.longitude && pos.longitude != 0)
                    this.longitude = pos.longitude;
                if(pos.latitude  && pos.latitude != 0)
                    this.latitude = pos.latitude;
            }
        }
        var h = localStorage.getItem("loginHash");
        if(h == null){
            h = this.generateUUID();
            localStorage.setItem("loginHash", h);
        }
        this.hash = h;
    }
    
    public Serialize() : string
    {
        return JSON.stringify(this);
    }

    private generateUUID() : string { 
        //if(crypto && typeof crypto.randomUUID !== "undefined")
        //    return crypto.randomUUID();

        var d = new Date().getTime();//Timestamp
        var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16;//random number between 0 and 16
            if(d > 0){//Use timestamp until depleted
                r = (d + r)%16 | 0;
                d = Math.floor(d/16);
            } else {//Use microseconds since page-load if supported
                r = (d2 + r)%16 | 0;
                d2 = Math.floor(d2/16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }
}
