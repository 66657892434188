import { Request } from './request';

export class RenewPasswordRequest extends Request {
    public password: string;
    public password2: string;
    public hash: string;

    constructor(password: string, password2: string, hash: string){
        super();
        this.password = password;
        this.password2 = password2;
        this.hash = hash;
    }
}