
import { Request } from './request';

export class SupportMessageRequest extends Request {
    public message: string;
    public email: string;

    constructor(message: string, email: string){
        super();
        this.message = message;
        this.email = email;
    }
}




