import { User } from 'src/app/model/user';
import { ProfileResponseImage } from './profile-response';
import { Response } from './response';

export class MessagesResponse extends Response {
    public data: Array<MessageSocket> = new Array<MessageSocket>();  
}

export class MessageSocket
{
    public firstname: string = "";
    public surname: string = "";
    public text: string = "";
    public age: number=  -1;
    public distance: number = -1;
    public avatar: string | ProfileResponseImage = "";
    public created: string = "";
    public id_user: string = null;
    public id_user_from: string = null;
    public gender: number = 0;
    public newMessage: number = 0;
    public online: number = 0;

    public static fromUser(user: User){
        const ret = new MessageSocket();
        ret.firstname = user.firstname;
        ret.surname = user.surname;
        ret.avatar = user.avatar;
        ret.created = new Date().toISOString().slice(0, 19).replace('T', ' ');
        ret.gender = user.gender;
        ret.id_user = user.id_hash;
        ret.id_user_from = user.id_hash;
        ret.online = user.online;
        ret.age = user.age;
        ret.distance = user.distance;
        ret.online = user.online;
        return ret;
    }
}