import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IonModal } from '@ionic/angular';
import { Subject } from 'rxjs';
import { AppState } from 'src/app-state';
import { MapLocation } from 'src/app/model/map-location';
import { User } from 'src/app/model/user';
import { HeightPipe } from 'src/app/pipes/height.pipe';
import { WeightPipe } from 'src/app/pipes/weight.pipe';
import { SocketService } from 'src/app/services/socket.service';
import { ProfileResponseData } from 'src/app/services/socket/profile-response';

@Component({
  selector: 'app-finish-registration-profile',
  templateUrl: './finish-registration-profile.component.html',
  styleUrls: ['./finish-registration-profile.component.scss'],
  providers: [WeightPipe, HeightPipe]
})
export class FinishRegistrationProfileComponent implements OnInit, OnDestroy {
  @Input() editProfile: ProfileResponseData | null = null;

  private disableSubscriptions = new Subject<boolean>();
  public loading: boolean = false;
  public profile : ProfileResponseData = null; 
  public pickMapLocation: MapLocation = null;
  private pickColName: string;
  private pickColEl: any;
  public dateSelectVisible: Map<string, boolean> = new Map();
  public errors: object = {};

  public inputs: Array<{name: string, type: string, list? : number[], common? : string, labelname?: string}> = []
  
  lists: Map<string, Array<{key: string, value: string}>> = new Map(); 
  speaking: Array<{id:string, name: string}> = [];
  speakingFiltred: Array<{id:string, name: string}> = [];

  constructor(
    private router: Router, 
    private socket: SocketService,
    private weightPipe: WeightPipe,
    private heightPipe: HeightPipe
  ) {  }

  ngOnInit() {

    if(!AppState.isLogged()){
      this.router.navigate(['/']);
      return;
    }
    if(this.editProfile){
      this.profile = new ProfileResponseData();
      this.profile.user = new User();
      Object.assign(this.profile.user, this.editProfile.user);
      this.initialize();     
    }else {
      this.loading = true;
      this.socket.getProfile(AppState.loggedUser.id_hash).subscribe(profile => {
        this.profile = profile.data;
        this.initialize();      
        this.loading = false;
      });
    }
    this.socket.getSpeaking().subscribe(resp => {
      if(resp.code == 200){
        for(var i in resp.data)
          this.speaking.push({
            id: i,
            name: resp.data[i]
          });
          this.speakingFiltred = [...this.speaking];
        }
    })
  }

  closeDatePicker(date: string, name: string){
    if(date != null)
        this.profile.user[name] = date;
    this.dateSelectVisible.delete(name);
    this.changeProfile();
  }

  initialize()
  {
    if(!this.profile.user.speaking)
      this.profile.user.speaking = "";
    this.inputs = User.getColumns(this.profile.user, this.editProfile != null);
    this.inputs.forEach(inp => {
      if(inp.type == "select" || inp.type == "multiselect"){
        if(inp.list){
          const data = new Array<{key: string, value: string}>();
          inp.list.forEach(val => {
            if(inp.name == "weight")
              data.push({key: val.toString(), value: this.weightPipe.transform(val)});
            else if(inp.name == "height")
              data.push({key: val.toString(), value: this.heightPipe.transform(val)});
          });
          this.lists.set(inp.name, data);
        } else {
          AppState.subscribeTranslationItems("list_" + inp.name, this.disableSubscriptions, data => {
              this.lists.set(inp.name, data);
          });
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.disableSubscriptions.next(true);
  }

  setMultiSelectValue(value: any, name: string)
  {
    if(typeof value == "object"){
      let setValue = value.join("|");
      if(setValue != "")
        setValue = "|" + setValue + "|";
      if(setValue != this.profile.user[name]){
        this.profile.user[name] = setValue;
        this.changeProfile();
      }
    }

  }

  setSpeaking(value: string, checked: boolean){
    if(checked){
      let newSpeaking = this.profile.user.speaking;
      if(newSpeaking == "")
        newSpeaking = "|";
      this.profile.user.speaking = newSpeaking + value + "|";
    } else {
      let newSpeaking = this.profile.user.speaking.replace("|" + value + "|", "|");
      this.profile.user.speaking = newSpeaking == "|" ? "" : newSpeaking;
    }
  }

  closeSpeakingModal(modal: IonModal){
    this.changeProfile();
    console.log(modal);
    modal.canDismiss = true;
    modal.dismiss();
    setTimeout(() => {
      modal.canDismiss = false;
    }, 100);
  }

  
  speakingSearchbarInput(ev) {
    this.filterList(ev.target.value);
  }

  filterList(searchQuery: string | undefined) {
    if (searchQuery === undefined) {
      this.speakingFiltred = [...this.speaking];
    } else {
      const normalizedQuery = searchQuery.toLowerCase(); 
      this.speakingFiltred = this.speaking.filter(item => {
        return item.name.toLowerCase().includes(normalizedQuery);
      });
    }
  }

  isSpeakingChecked(value: string){
    return this.profile.user.speaking.indexOf("|" + value + "|") != -1;
  }

  changeProfile(){
    //this.errors = {};
    this.socket.updateProfile(this.profile.user).subscribe(response => {
      AppState.updateLoggedUser(response.data.user);
      SocketService.updateMyProfileSubject.next(response.data);   
      this.errors = response.data.errors ? response.data.errors : {};
    });
  }

  clearLocation(name: string){
    this.profile.user[name+ "_text"] = 
    this.profile.user[name+ "_coordinates_lat"] = 
    this.profile.user[name+ "_coordinates_lon"] = null;
    this.changeProfile();
  }

  locationClick(el: any, name: string){
    this.pickColName = name;
    this.pickColEl = el.target;
    const mapLocation = new MapLocation();
    if(this.profile.user[this.pickColName + "_coordinates_lon"] != null){
      mapLocation.name = this.profile.user[this.pickColName + "_text"];
      mapLocation.latitude = this.profile.user[this.pickColName + "_coordinates_lat"];
      mapLocation.longitude = this.profile.user[this.pickColName + "_coordinates_lon"];
    }
    //el.target.value = "test";
    this.pickMapLocation = mapLocation;
  }
  
  onCloseMapPicker(location: MapLocation){
    if(!location){
      this.pickMapLocation = null;
      return;
    }

    this.profile.user[this.pickColName + "_text"] = location.name;
    this.profile.user[this.pickColName + "_coordinates_lat"] = location.latitude;
    this.profile.user[this.pickColName + "_coordinates_lon"] = location.longitude;
    this.changeProfile();

    this.pickColEl.value = location.name;
    this.pickMapLocation = null;
  }

}
