import { Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe } from './translate.pipe';

@Pipe({
  name: 'weight'
})
export class WeightPipe extends TranslatePipe {

  transform(value: number): string {
    let sign = "";
    if(value < 40){
      sign = "< ";
      value = 40;
    }
    else if(value > 150){
      sign = "> ";
      value = 150;
    }
    return sign + value + " kg / " + Math.round(value * 2.2046) + " lb";
  }

}
