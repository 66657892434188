import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'date'
})
export class DatePipe implements PipeTransform {

  transform(value: string, time: boolean = false, shortTime: boolean = false): string {
    if (value == '')
      return '';
    value = value.replace(/-/g, "/");
    if (!time)
      return (new Date(value)).toLocaleDateString();
    else if(shortTime)  
      return (new Date(value)).toLocaleDateString() + ' ' + (new Date(value)).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
    else
      return (new Date(value)).toLocaleDateString() + ' ' + (new Date(value)).toLocaleTimeString();
  }
}
