import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zodiac'
})
export class ZodiacPipe implements PipeTransform {

  transform(value:string): string {
    const tmp = value.split('-');
    const month = parseInt(tmp[1]);
    const day = parseInt(tmp[2]);

    if (month == 3)
      if (day >= 2)
        return "1";
      else
        return "12";
    if (month == 4)
      if (day >= 21)
        return "2";
      else
        return "1";
    if (month == 5)
      if (day >= 22)
        return "3";
      else
        return "2";
    if (month == 6)
      if (day >= 22)
        return "4";
      else
        return "3";
    if (month == 7)
      if (day >= 23)
        return "5";
      else
        return "4";
    if (month == 8)
      if (day >= 23)
        return "6";
      else
        return "5";
    if (month == 9)
      if (day >= 23)
        return "7";
      else
        return "6";
    if (month == 10)
      if (day >= 24)
        return "8";
      else
        return "7";
    if (month == 11)
      if (day >= 23)
        return "9";
      else
        return "8";
    if (month == 12)
      if (day >= 22)
        return "10";
      else
        return "9";
    if (month == 1)
      if (day >= 21)
        return "11";
      else
        return "10";
    if (month == 2)
      if (day >= 21)
        return "12";
      else
        return "11";
  }

}
