
import { Request } from './request';

export class DeleteImageRequest extends Request {
    id_image: number = -1;
    idHash: string = null;
    constructor(id_image: number, id_hash: string){
        super();
        this.id_image = id_image;
        this.idHash = id_hash;
    }
}
