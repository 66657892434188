import { AppState } from 'src/app-state';
import { Request } from './request';
import { Response } from './response';


export class ForgottenPasswordRequest extends Request {
    email? : string;
    phone?: string;
    id_lang = AppState.lang.getValue()
}


export class ForgottenPasswordPinRequest extends Request {
    phone: string;
    pin: string;
}

export class ForgottenPasswordPinResponse extends Response {
    public data: {
        hash: string
    };  
}
