import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[OnlyNumber]'
})
export class OnlyNumbersDirective {
  constructor(private el: ElementRef) {
    if(el && el.nativeElement)
      el.nativeElement.setAttribute("pattern", "\\d*")
  }
  @Input() OnlyNumber: string;
  /*
  @HostListener('keydown', ['$event']) onKeyDown(event) {
    let e = <KeyboardEvent> event;
    if (this.OnlyNumber == "true") {
      if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+C
        (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+V
        (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+X
        (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)) {
          // let it happen, don't do anything
          return;
        }
        // Ensure that it is a number and stop the keypress
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }
      }
  }
  
  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if (this.OnlyNumber === "true") {
      const allowedKeys = ['Delete', 'Backspace', 'Enter', 'Tab'];
      const isNumericInput = (event.key >= '0' && event.key <= '9') || (event.key >= 'Numpad0' && event.key <= 'Numpad9');
  
      if (
        allowedKeys.includes(event.key) ||
        // Allow: Ctrl+A
        (event.key === 'a' && (event.ctrlKey || event.metaKey)) ||
        // Allow: Ctrl+C
        (event.key === 'c' && (event.ctrlKey || event.metaKey)) ||
        // Allow: Ctrl+V
        (event.key === 'v' && (event.ctrlKey || event.metaKey)) ||
        // Allow: Ctrl+X
        (event.key === 'x' && (event.ctrlKey || event.metaKey)) ||
        // Allow: home, end, left, right
        (event.key === 'Home' || event.key === 'End' || event.key === 'ArrowLeft' || event.key === 'ArrowRight')
      ) {
        // Let it happen, don't do anything
        return;
      }
  
      // Ensure that it is a number and stop the keypress
      if (!isNumericInput) {
        event.preventDefault();
      }
    }
  }
  */
 
  private readonly allowedKeys = ['Delete', 'Backspace', 'Enter', 'Tab', 'Home', 'End', 'ArrowLeft', 'ArrowRight'];
  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if (this.OnlyNumber === "true") {
      if (this.allowedKeys.includes(event.key))
        return;
      const isCtrlShortcut = (event.key === 'a' || event.key === 'c' || event.key === 'v' || event.key === 'x') && (event.ctrlKey || event.metaKey);
      if(isCtrlShortcut)
        return;
      const isNumericInput = (event.key >= '0' && event.key <= '9') || (event.key >= 'Numpad0' && event.key <= 'Numpad9');
      if (!isNumericInput)
        event.preventDefault();
    }
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    if (this.OnlyNumber === "true") {
      const val = event.clipboardData.getData('text/plain');
      this.el.nativeElement.value = val.replace(/[^0-9]/g, '');
      event.preventDefault();
    }
  }
}
