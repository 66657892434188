import { User } from 'src/app/model/user';
import { Request } from './request';
import { AppState } from 'src/app-state';

export class UpdateProfileRequest extends Request {
    user: User;
    id_lang = AppState.lang.getValue()

    constructor(user: User){
        super();
        this.user = user;
    }
}
