import { Component, OnInit } from '@angular/core';
import { AppState } from 'src/app-state';
import { SocketService } from 'src/app/services/socket.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

  public errorMessage: string = null;
  public success: boolean = false;
  public emailFrom: string = "";
  public loading: boolean = false;
  constructor(private socket: SocketService) { }

  ngOnInit() {
    this.emailFrom = AppState.loggedUser.email ? AppState.loggedUser.email : "";
  }


  sendMessage(ev: any, messageEl: HTMLTextAreaElement, emailEl: HTMLInputElement){
    this.success = false;
    ev.preventDefault();
    if(messageEl.value.trim() == "")
      return;
    this.errorMessage = "";
    this.loading = true;
    this.socket.sendSupportMessage(messageEl.value, emailEl.value).subscribe(resp => {
      if(resp.code == 200){
        this.success = true;
        messageEl.value = "";
        emailEl.value = "";
      } else {
        this.errorMessage = resp.message;
      }
      this.loading = false;
    });
  }

}
