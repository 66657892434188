import { User } from "src/app/model/user";
import { Response } from './response';

export class DashboardResponse extends Response {
    public data: DashboardResponseData = new DashboardResponseData();  
}

export class DashboardResponseData
{
    public users: Array<User> = [];
    public maxPage: number = 0;
}