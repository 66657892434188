import { Request } from './request';

export class FormDataAuthRequest {
    public jsonObject: Request;
    public method: string;

    constructor(jsonObject: Request, method: string){
        this.jsonObject = jsonObject;
        this.method = method;
    }

    public Serialize() : string
    {
        return JSON.stringify(this);
    }
}
