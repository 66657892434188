import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'genderClassname'
})

export class GenderClassnamePipe implements PipeTransform {
  transform(gender: number, args?: any): string {
    switch ( gender ) {
      case 2:
        return "female";
      case 3:
        return "trans-male";
      case 4:
        return "trans-female";
      case 5:
          return "other";
      default: 
        return "male";
   }
  }
}
