import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-date-select',
  templateUrl: './date-select.component.html',
  styleUrls: ['./date-select.component.scss']
})
export class DateSelectComponent implements OnInit, OnDestroy, AfterViewInit {
  months: Array<number> = [];
  days: Array<number> = [];
  years: Array<number> = [];

  selectedDay: number = 0;
  selectedMonth: number = 0;
  selectedYear: number = 0;
  @Output() onClose: EventEmitter<string | null> = new EventEmitter();  
  @Input() headline: string = "";
  @Input() value: string = "";
  okEnable: boolean = false;

  constructor() { }
  ngOnInit() {
    document.getElementsByTagName("body")[0].classList.add("pullToRefreshDisabled");
    for(let i = 1; i <= 31; i++)
      this.days.push(i);
    for(let i = 1; i <= 12; i++)
      this.months.push(i);
    const minYear = new Date().getFullYear() - 100;
    for(let i = new Date().getFullYear(); i >= minYear; i--)
      this.years.push(i);
    const v = this.value.split("-");
    if(v.length > 2){
      this.selectedYear = Number(v[0]);
      this.selectedMonth = Number(v[1]);
      this.selectedDay = Number(v[2]);
    }
    this.isDateValid();
  }


  ngOnDestroy(): void {
    document.getElementsByTagName("body")[0].classList.remove("pullToRefreshDisabled");
  }

  ngAfterViewInit(): void {
    const els = document.getElementsByClassName("date-selected");
    for (let i = 0; i < els.length; i++)
      els[i].scrollIntoView();
  }

  isDateValid(){
    this.okEnable = new Date(this.getDateString()).toLocaleDateString() !== "Invalid Date";
  }


  cancel(){
    this.onClose.emit(null);
  }

  confirm(){
    if(!this.okEnable)
      return;
    this.onClose.emit(this.getDateString());
  }

  getDateString(){
    return this.selectedYear + "-" + this.get2digits(this.selectedMonth) + "-" + this.get2digits(this.selectedDay);
  }

  get2digits(input : number)
  {
    if(input < 10)
      return "0" + input;
    return input.toString();
  }

}
