import { Pipe, PipeTransform } from '@angular/core';
import { SocketService } from '../services/socket.service';
import { ProfileResponseImage } from '../services/socket/profile-response';

@Pipe({
  name: 'imageUrl'
})
export class ImageUrlPipe implements PipeTransform {
  constructor(private client: SocketService){}

  transform(value: string | ProfileResponseImage): any {
    if(typeof value == "string")
      return this.client.getImageUrl(value);
    return this.client.getImageUrl(value.src);
  }
}