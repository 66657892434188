import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { icon, latLng, Marker, TileLayer, tileLayer } from 'leaflet';
import { Subject } from 'rxjs';
import { AppState } from 'src/app-state';
import { MapLocation } from 'src/app/model/map-location';
import { SocketService } from 'src/app/services/socket.service';
import { WebapiService } from 'src/app/services/webapi.service';

@Component({
  selector: 'app-leaflet-map',
  templateUrl: './leaflet-map.component.html',
  styleUrls: ['./leaflet-map.component.scss']
})
export class LeafletMapComponent implements OnInit, OnDestroy {

  initalized: boolean = false;
  options: any;
  layer: TileLayer;
  @Input() lat: number | null = null;
  @Input() lng: number | null = null;
  @Output() changeLocation: EventEmitter<MapLocation> = new EventEmitter();  
  @Input() changeLocationSubject: Subject<MapLocation> = new Subject();  
  @Input() autoLoadMarkers: boolean = false;

  marker: Marker = null;
  private map: any = null;
  private showMarkerOnInit: boolean = true;
  
  constructor(private socket: SocketService, private webApi: WebapiService) {
    const iconRetinaUrl = 'assets/img/marker-icon-2x.png';
    const iconUrl = 'assets/img/marker-icon.png';
    const shadowUrl = 'assets/img/marker-shadow.png';
    const iconDefault = icon({
      iconRetinaUrl,
      iconUrl,
      shadowUrl,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      tooltipAnchor: [16, -28],
      shadowSize: [41, 41]
    });
    Marker.prototype.options.icon = iconDefault;
  }
  ngOnDestroy(): void {
    this.changeLocationSubject.unsubscribe();
  }

  ngOnInit() {
    if(this.lat == null && this.lng == null){
      let latLngIsset = false;
      const geo = AppState.geoPosition.getValue();
      if(AppState.isLogged() && geo.latitude == 0 && geo.longitude == 0){
        this.showMarkerOnInit = false;
        this.webApi.getDialCodes().subscribe(resp => {
          const dialCode = resp.data.find(t => t.id == AppState.loggedUser.id_dial_code);
          if(dialCode){
            this.lat = dialCode.lat;
            this.lng = dialCode.lon;
            const latlng = latLng(this.lat, this.lng);
            if(this.map != null)
              this.map.setView(latlng, this.map.zoom);
            return false;
          }
        });
      }
      if(!latLngIsset){
        this.lat = geo.latitude;
        this.lng = geo.longitude;
        if(geo.latitude != 0 || geo.longitude != 0)
          this.emitChangePosition();
      }
    }
    this.changeLocationSubject.subscribe((val: MapLocation) => {
      this.lat = val.latitude;
      this.lng = val.longitude;
      const latlng = latLng(this.lat, this.lng);
      this.createPositionMarker();
      this.marker.setLatLng(latlng);
      if(this.map != null)
        this.map.setView(latlng, 11);
      this.emitChangePosition();
    });
    this.layer = tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18});

    this.options = {
      layers: [
        this.layer
      ],
      zoom: 7,
      center: latLng(this.lat, this.lng)
    };
    this.initalized = true;
  }

  createPositionMarker(){
    if(this.marker != null)
      return;
    this.marker = new Marker(latLng(this.lat, this.lng), {draggable: true});
    this.marker.on('dragend', (event) => {
      const latlng = event.target.getLatLng();
      this.lat = latlng.lat;
      this.lng = latlng.lng;
      this.emitChangePosition();
    });
    this.map.addLayer(this.marker);
  }

  onMapReady(map: any) {
    this.map = map;
    if(!this.autoLoadMarkers) {
      if(this.showMarkerOnInit)
        this.createPositionMarker();
      map.on("click", (event) => {
        const latlng = event.latlng;
        this.lat = latlng.lat;
        this.lng = latlng.lng;
        this.createPositionMarker();
        this.marker.setLatLng(latlng);
        this.emitChangePosition();
      });
    } else {
      this.loadMarkersByPosition();
      map.on('moveend', () => { 
        this.loadMarkersByPosition();
      });
    }
  }

  loadMarkersByPosition(){
    const bounds = this.map.getBounds();
    const north = bounds.getNorth(); 
    const south = bounds.getSouth();
    const west = bounds.getWest();
    const east = bounds.getEast(); 
  }

  emitChangePosition(){
    this.changeLocation.emit({
      name: "", 
      latitude: this.lat, 
      longitude: this.lng
    });
  }
}
