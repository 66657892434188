import { AppState } from "src/app-state";

export class filters {
    age: filtersAge = new filtersAge();
    gender: Array<string> = [];
    onlyNewMessages: boolean = false;
    onlyAvatar: boolean = false;
    onlyOnline: boolean = false;
}

export class filtersAge{
    lower: number = -1;
    upper: number = -1;
}