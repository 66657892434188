import { Request } from './request';

export class changePositionsRequest extends Request {
    positions: Array<number>;

    constructor(positions: Array<number>){
        super();
        this.positions = positions;
    }
}
