import { ProfileResponseImage } from "../services/socket/profile-response";

export class User {
    id_hash: string = null;
    coins: number = 0;
    firstname: string = "";
    surname: string = "";
    phone: string = "";
    email: string = null;
    id_dial_code: number = -1;
    avatar: string| ProfileResponseImage = "";
    avatar_big: string | ProfileResponseImage = "";
    hasImage: boolean = false;
    latitude: number = 0;
    longitude: number = 0;
    distance: number = 0;
    pets: number = 0;
    show_zodiac_sign: number = 0;
    speaking: string = "";
    speaking_text: string = "";
    zodiac: string;
    birthdate: string = "";
    age: number = 0;
    gender: number = 0; 
    smth_about_me: string = "";
    profession: string = "";
    isFriend: boolean = false;
    online: number = 0;
    active: number = 0;
    education: number = 0;
    status: number = 0;
    looking_for: number = 0;
    children: number = 0;
    sexual_orientation: number = 0;
    sexual_experiences: number = 0;
    racial_category: number = 0;
    diet: number = 0;
    eye_color: number = 0;
    hair_color: number = 0;
    beard: number = 0;
    hair_length: number = 0;
    glasses: number = 0;
    piercing: number = 0;
    tattoo: number = 0;
    smoking: number = 0;
    alcohol: number = 0;
    drugs: number = 0;
    weight: number = 0;
    height: number = 0;
    breast_size: number = 0;
    created: string = "";
    last_activity: string = "";
    born_at_coordinates_lat: number = 0;
    born_at_coordinates_lon: number = 0;
    living_at_coordinates_lat: number = 0;
    living_at_coordinates_lon: number = 0;
    living_at_text: string = "";
    born_at_text: string = "";
    msg_count: number = 0;
    hasCoordinates: boolean = false;

    public static getHeights() : number[]{
        const ret = [];
        for(var i = 119; i <= 211; i++){
            ret.push(i);
        }
        return ret;
    }

    public static getWeights() : number[]{
        const ret = [];
        for(var i = 39; i <= 151; i++)
            ret.push(i);
        return ret;
    }

    public static getColumns(user: User, editProfile: boolean) {
        const ret : Array<{name: string, type: string, list?: number[], common? : string, labelname? : string}> = [
            {name: "basic_info", type: "headline"},
            {name: "smth_about_me", type: "textarea"},
            {name: "born_at", type: "location"},
            {name: "living_at", type: "location"},
            {name: "profession", type: "text"},
            {name: "education", type: "select"},
            {name: "status", type: "select"},
            {name: "looking_for", type: "select"},
            {name: "children", type: "select"},
            {name: "diet", type: "select"},
            {name: "speaking", type: "speaking"},
            {name: "pets", type: "select"},
            {name: "show_zodiac_sign", type: "checkbox"},
            
            {name: "appearance", type: "headline"},
            {name: "racial_category", type: "select"},
            {name: "weight", type: "select", list: this.getWeights()},
            {name: "height", type: "select", list: this.getHeights()},
            {name: "eye_color", type: "select"},
            {name: "hair_color", type: "select"},
            {name: "hair_length", type: "select"},
            {name: "glasses", type: "select"},
            {name: "piercing", type: "select"},
            {name: "tattoo", type: "select"},

            {name: "addictions", type: "headline"},
            {name: "smoking", type: "select"},
            {name: "alcohol", type: "select"},
            {name: "drugs", type: "select"},

            {name: "intimacy", type: "headline"},
            {name: "sexual_orientation", type: "select"},
            {name: "sexual_experiences", type: "select"},
        ];

        let idx = 1;
        if(editProfile){
            ret.splice(idx, 0, {name: "firstname", type: "text", common: "placeholder", labelname: "registration_firstname"});
            ret.splice(idx + 1, 0, {name: "surname", type: "text", common: "placeholder", labelname: "registration_surname"});
            ret.splice(idx + 2, 0, {name: "birthdate", type: "date", common: "placeholder", labelname: "registration_birth_date"});
            ret.splice(idx + 3, 0, {name: "gender", type: "select", labelname: "gender"});
            idx+=4;
        }

        idx += 30;
        if([2, 4, 5].indexOf(user.gender) != -1){
            ret.splice(idx, 0, {name: "breast_size", type: "select"});
            idx+=1
        }    

        idx +=20;
        if([1, 3, 5].indexOf(user.gender) != -1){
             ret.splice(20, 0, {name: "beard", type: "select"});
             idx+=1
        }

        return ret;
    }
}