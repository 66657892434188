import { animate, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { MapLocation } from 'src/app/model/map-location';

@Component({
  selector: 'app-location-picker',
  templateUrl: './location-picker.component.html',
  styleUrls: ['./location-picker.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({opacity: 0}),
          animate('300ms', style({opacity: 1}))
        ]),
        transition(':leave', [
          style({ opacity: 1}),
          animate('300ms', style({opacity: 0}))
        ])
      ]
    )
  ]
})
export class LocationPickerComponent implements OnInit {

  @Input() mapLocation: MapLocation = new MapLocation();  
  @Input() required: boolean = false;
  @Input() message: string = null;  
  @Output() onClose: EventEmitter<MapLocation | null> = new EventEmitter();  
  lat: number = null;
  lng: number = null;
  searchItems: Array<{lat: number, lon: number, display_name: string}> = null;
  private searchObservable : Subscription = null;
  searchLoading: boolean = false;
  searchTimeout: any = null;
  setLocationSearch = new Subject<MapLocation>();
  private searchBefore = "";
  
  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.searchBefore = "";
    if(
      this.mapLocation.latitude 
      && this.mapLocation.longitude
      && this.mapLocation.latitude != 0
      && this.mapLocation.longitude != 0
    ){
      this.lat = this.mapLocation.latitude;
      this.lng = this.mapLocation.longitude;
    }
  }

  close(setLocation: boolean) {
    if(!setLocation)
      this.onClose.emit(null);
    else
      this.setMapLocation();
  }

  setMapLocation(){    
    this.http.get(
      "https://nominatim.openstreetmap.org/reverse?format=json&lat=" +  this.mapLocation.latitude + "&lon=" + this.mapLocation.longitude
    ).subscribe((val: any) => {
      if(val.address){
        //if(val.address.country)
        //  console.log(val.address.country);
        if(val.address.city)
          this.mapLocation.name = val.address.city;
        else if(val.address.town)
          this.mapLocation.name =val.address.town;
        else if(val.address.village)
          this.mapLocation.name = val.address.village;
      }
      this.onClose.emit(this.mapLocation);
    });
  }

  changeLocation(location: MapLocation){
    this.mapLocation = location;
  }

  searchLocation(event: any, el: HTMLInputElement){
    const value = el.value;
    if(event.keyCode == 13 && this.searchItems && this.searchItems.length > 0){
      this.setSearchItemPosition(this.searchItems[0], el);
      return;
    }
    if(this.searchBefore == value)
      return;
    this.searchBefore = value;
    this.searchLoading = false;
    if(this.searchObservable != null)
      this.searchObservable.unsubscribe();
      this.searchItems = null;
      if(this.searchTimeout != null)
        clearTimeout(this.searchTimeout);
      if(value.length >= 2){
        this.searchLoading = true;
        this.searchTimeout = setTimeout(() => {
          this.searchObservable = this.http.get<any>(
            "https://nominatim.openstreetmap.org/search?format=json&city=" +  encodeURIComponent(value)
          ).subscribe((val: Array<{lat: number, lon: number, display_name: string}>) => {
            this.searchLoading = false;
            this.searchItems = val;
          });
        }, 500);
    }
  }

  setSearchItemPosition(item: {lat: number, lon: number, display_name: string}, el: HTMLInputElement){
    this.searchBefore = el.value = "";
    this.searchItems = null;
    this.setLocationSearch.next({
      latitude: item.lat,
      longitude: item.lon,
      name: item.display_name
    })
  }

}
