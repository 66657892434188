import { Pipe, PipeTransform } from '@angular/core';
import { AppState } from 'src/app-state';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {

  transform(value: string | number, name: string, replaces: object = null): string {
    return this.translate(value, name, replaces);
  }

  protected translate(value: string | number, name: string, replaces: object = null){
    return TranslatePipe.get(value, name, replaces);
  }

  public static get(value: string | number, name: string, replaces: object = null){
    const translations = AppState.translations.getValue();
    if(!translations.hasOwnProperty(name))
      return "*[" + name + "|" + value + "]*";

    type ObjectKey = keyof typeof translations;
    let testKey = name as ObjectKey;
    const values = translations[name];

    if(!values.hasOwnProperty(value))
      return "*[" + name + "|" + value + "]*";
      
    testKey = value as ObjectKey;
    let ret = values[testKey];
    if(replaces)
    for (let key in replaces)
      ret = ret.replace("[[" + key + "]]", replaces[key]);
    return ret;
  }

}
