import { BehaviorSubject, Observable, Subject} from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Lang } from "./app/model/lang";
import { User } from "./app/model/user";
import { ChatMessageSocket } from "./app/services/socket/chat-response";
import { MessageSocket } from "./app/services/socket/messages-response";
import { filters } from "./app/model/filters";
import { environment } from "./environments/environment";
import { CreateUserRequest } from "./app/services/socket/create-user-request";

export class AppState {
    public static version: string = "0.0.7";
    public static enableGeoPosition: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public static geoPosition: BehaviorSubject<{longitude: number, latitude: number}> = new BehaviorSubject({longitude: 0, latitude: 0});
    public static countryCode: BehaviorSubject<string> = new BehaviorSubject("");
    public static loggedUser: User = null;
    public static socketUrl: string = environment.socketUrl;
    public static serverUrl: string = environment.serverUrl;
    public static friends: Subject<Array<User>> = new Subject<Array<User>>();
    public static chatMessages: BehaviorSubject<Array<ChatMessageSocket>> = new BehaviorSubject<Array<ChatMessageSocket>>([]);
    public static messages: BehaviorSubject<Array<MessageSocket>> = new BehaviorSubject<Array<MessageSocket>>([]);
    public static messageDetailIdUser: string = null;
    public static newMessagesCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public static debugMessage: BehaviorSubject<string> = new BehaviorSubject<string>("");
    public static bodyClick: Subject<HTMLElement> = new Subject<HTMLElement>();
    public static toggleMenu: Subject<boolean> = new Subject<boolean>();
    public static activeMenuLinkName: string = "";
    private static selectedFilters: Map<string, filters> = new Map();

    public static Langs : BehaviorSubject<Map<string, Lang>> =  new BehaviorSubject(new Map([["en", {id: "2", name: "English"}]]));
    public static lang : BehaviorSubject<string> = new BehaviorSubject(localStorage.getItem("lang") ? localStorage.getItem("lang") :  "2");

    public static translations : BehaviorSubject<any> = new BehaviorSubject<any>({});
    public static minimal_allowed_age: BehaviorSubject<number> = new BehaviorSubject(1);
    public static maximal_allowed_age: BehaviorSubject<number> = new BehaviorSubject(99);

    public static subscribeTranslationItems(
        keyTrans: string, 
        tkUntil: Observable<any>,
        subscribe: (value: Array<{key: string, value: string}> ) => void)
    {
        this.translations
            .pipe(takeUntil(tkUntil))
            .subscribe(translations => {
                if(translations.hasOwnProperty(keyTrans)){
                    const ret = new Array<{key: string, value: string}>();
                    Object.keys(translations[keyTrans]).forEach(key => {
                        ret.push({key: key, value: translations[keyTrans][key]});
                    });
                    subscribe(ret);
                }
            });
    }

    public static isLogged(): boolean{
        let data = localStorage.getItem("logged");
        if(data == null || data == "false")
            return false;
        if(AppState.loggedUser == null)
            AppState.loggedUser = JSON.parse(data);
        return true;
    }

    public static saveCreateUser(user: CreateUserRequest)
    {
        if(!user.id_hash)
            return;
        localStorage.setItem("tmpCreateUser", JSON.stringify(user));
    }    

    public static getCreateUser(id_hash: string): CreateUserRequest
    {
        const user = new CreateUserRequest();
        let storage : any = localStorage.getItem("tmpCreateUser");
        if(storage){
            storage = JSON.parse(storage);
            if(storage.id_hash && storage.id_hash == id_hash){
                Object.assign(user, storage);
            }
        }
        return user;
    }

    public static clearCreateUser()
    {
        localStorage.removeItem("tmpCreateUser");
    }

    public static updateLoggedUser(user: User){
        AppState.clearCreateUser();
        localStorage.setItem("logged", JSON.stringify(user));
        AppState.loggedUser = user;
        AppState.newMessagesCount.next(user.msg_count);
    }

    private static getFilterKey(key: string){
        return "filters_" + key + "_" + AppState.loggedUser.id_hash;
    }

    public static getSelectedFilters(key: string = "people_nearby"): filters
    {
        const filterKey = AppState.getFilterKey(key);
        if(AppState.selectedFilters.has(filterKey))
            return AppState.selectedFilters.get(filterKey);
        const ret = new filters();
        const f = localStorage.getItem(filterKey);
        if(f)
            Object.assign(ret, JSON.parse(f))
        return ret;
    }

    public static setSelectedFilters(filters: filters, key: string = "people_nearby"): filters
    {
        const filterKey = AppState.getFilterKey(key);
        AppState.selectedFilters.set(filterKey, filters);
        localStorage.setItem(filterKey, JSON.stringify(filters));
        return filters;
    }
}
