import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import SwiperCore, {Pagination, Scrollbar, Zoom, Thumbs} from 'swiper';
import { SwiperComponent } from 'swiper/angular';

SwiperCore.use([Pagination, Scrollbar, Zoom, Thumbs]);

@Component({
  selector: 'app-photo-slider-component',
  templateUrl: './photo-slider-component.component.html',
  styleUrls: ['./photo-slider-component.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({opacity: 0}),
          animate('300ms', style({opacity: 1}))
        ]),
        transition(':leave', [
          style({ opacity: 1}),
          animate('300ms', style({opacity: 0}))
        ])
      ]
    )
  ]
})
export class PhotoSliderComponentComponent implements OnInit, OnDestroy {
  @Input() photos : Array<string> = new Array<string>();  
  @Input() preselectedPhoto : string = "";  
  @Input() showEditIcons : boolean = false;  

  @Output() onClose : EventEmitter <boolean> = new EventEmitter<boolean>();  
  @Output() onDelete : EventEmitter <number> = new EventEmitter<number>();  
  @Output() onCrop : EventEmitter <number> = new EventEmitter<number>();  

  initialSlide: number = 0;
  visible: boolean = true;
  scale: number = 1;
  enabled: boolean = true;
  allowSlidePrev: boolean = false;
  allowSlideNext: boolean = false;
  
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  constructor() {}

  ngOnDestroy(): void {
    document.getElementsByTagName("body")[0].classList.remove("photoslider");
  }

  ngOnInit() {
    for(let i  in this.photos){
      if(this.photos[i] == this.preselectedPhoto){
        this.initialSlide = Number(i);
        break;
      }
    }
    document.getElementsByTagName("body")[0].classList.add("photoslider");
  }

  indexChanged(){
    setTimeout(() => {
      this.allowSlidePrev = this.swiper.swiperRef.activeIndex > 0;
      this.allowSlideNext = this.swiper.swiperRef.activeIndex < this.photos.length - 1;
    }, 1);
  }

  close(event: any) {
    if(event.target.tagName.toLowerCase() == "img")
      return;
    this.visible = false;
    setTimeout(() => {
      this.onClose.emit(true);
    }, 300);
  }

  changeScale(scale){
    this.enabled = scale <= 1;
  }

  deleteImage(event){
    this.onDelete.emit(this.swiper.swiperRef.activeIndex);
    event.stopPropagation();
  }

  crop(event){
    this.onCrop.emit(this.swiper.swiperRef.activeIndex);
    event.stopPropagation();
  }

  photoClick(event){
    if(!this.enabled)
      return;
    if(event.clientX < event.target.offsetLeft + (event.target.clientWidth / 2))
      this.slidePrev(event);
    else 
      this.slideNext(event);
  }

  slidePrev(event) {
    this.swiper?.swiperRef.slidePrev();
    event.stopPropagation();
  }

  slideNext(event) {
    this.swiper?.swiperRef.slideNext();
    event.stopPropagation();
  }
}
