import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppState } from 'src/app-state';
import { AddPhotoRequest } from './socket/add-photo-request';
import { CreateUserRequest } from './socket/create-user-request';
import { CreateUserResponse } from './socket/create-user-response';
import { FormDataAuthRequest } from './socket/form-data-auth-request';
import { LoginRequest } from './socket/login-request';
import { LoginResponse } from './socket/login-response';
import { ProfileRequest } from './socket/profile-request';
import { ProfileResponse } from './socket/profile-response';
import { Response } from './socket/response';
import { Request } from './socket/request';
import { ForgottenPasswordRequest, ForgottenPasswordPinRequest, ForgottenPasswordPinResponse} from './socket/forgotten-password';
import { RenewPasswordRequest } from './socket/renew-password-request';
import { DialCodesResponse } from './socket/dial-codes-response';

@Injectable({
  providedIn: 'root'
})
export class WebapiService {
  private dialCodesData$: ReplaySubject<DialCodesResponse> = new ReplaySubject<DialCodesResponse>();

  constructor(private http: HttpClient ) {
    
   }

  public login(username: string, password: string) : Observable<LoginResponse>
  {
    var reg = new LoginRequest(username, password);
    return this.http.post<LoginResponse>(AppState.serverUrl + "/api", {jsonObject: reg, method: "login"});
  }  

  getProfile(id_hash: string) : Observable<ProfileResponse>
  {
    var reg = new ProfileRequest(id_hash);
    return this.http.post<ProfileResponse>(AppState.serverUrl + "/api", {jsonObject: reg, method: "getProfile"});
  }

  getDialCodes() : Observable<DialCodesResponse>
  {
    if (!this.dialCodesData$.observers.length) {
      this.http.post<DialCodesResponse>(AppState.serverUrl + "/api", {jsonObject: new Request(), method: "getDialCodes"})
        .pipe(
          tap((dialCodesResponse) => {
            this.dialCodesData$.next(dialCodesResponse);
          })
        ).subscribe();
    }
    return this.dialCodesData$.asObservable();
  }

  public getTranslations(id: string) : Observable<any>
  {
    return this.http.get<any>(AppState.serverUrl + "/get-translations?id_lang=" + id);
  }  

  public getTermsAndCoditions() : Observable<string>
  {
    return this.http.get(AppState.serverUrl + "/get-terms-and-conditions", {responseType: "text"});
  }  

  public createUser(request: CreateUserRequest) : Observable<CreateUserResponse>
  {
    return this.http.post<CreateUserResponse>(AppState.serverUrl + "/api", {jsonObject: request, method: "createUser"});
  } 
  
  public addPhoto(id_hash: string, formData: FormData) : Observable<ProfileResponse>
  {
    const request = new AddPhotoRequest(id_hash);
    formData.append('auth', (new FormDataAuthRequest(request, "addImage")).Serialize());
    return this.http.post<ProfileResponse>(AppState.serverUrl + "/api", formData);
  } 

  public sendForgottenPassword(phone: string, email: string) : Observable<Response>
  {
    const request = new ForgottenPasswordRequest();
    if(phone.trim().length > 0)
      request.phone = phone.trim();
    else if(email.trim().length > 0)
      request.email = email.trim();
    return this.http.post<Response>(AppState.serverUrl + "/api", {jsonObject: request, method: "sendForgottenPassword"});
  }

  forgottenPasswordVerifyPin(pin: string, phone: string) : Observable<ForgottenPasswordPinResponse>
   {
    const request = new ForgottenPasswordPinRequest();
    request.pin = pin;
    request.phone = phone;
    return this.http.post<ForgottenPasswordPinResponse>(AppState.serverUrl + "/api", {jsonObject: request, method: "forgottenPasswordVerifyPin"});
  }

  renewPassword(password: string, password2: string, hash: string) : Observable<LoginResponse>
  {
    const request = new RenewPasswordRequest(password, password2, hash);
    return this.http.post<LoginResponse>(AppState.serverUrl + "/api", {jsonObject: request, method: "renewPassword"});
  }

}
