import { Response } from './response';

export class ChatResponse extends Response {
    public data: Array<ChatMessageSocket>
}


export class ChatMessageSocket
{
    public id: number;
    public created: string;
    public id_user_from : string;
    public id_user_to : string;
    public text : string;
    public new: number;
}