
import { Request } from './request';

export class MessageRequest extends Request {
    public idHash: string;
    public message: string;

    constructor(id_hash: string, message: string){
        super();
        this.idHash = id_hash;
        this.message = message;
    }
}




