import { AppState } from 'src/app-state';
import { DeviceSerializedData } from './login-request';
import { Request } from './request';

export class VerifyPhoneRequest extends Request{
    public pin: string;
    public idHash: string;
    public invitation: string;
    public serialized_data = new DeviceSerializedData();
    public id_lang = AppState.lang.getValue()
}
