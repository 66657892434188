
import { Request } from './request';

export class VerifyEmailRequest extends Request {
    public emailHash: string;

    constructor(hash: string){
        super();
        this.emailHash = hash;
    }
}
