import { animate, style, transition, trigger } from '@angular/animations';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CropperPosition, ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { ImageUrlPipe } from 'src/app/pipes/image-url.pipe';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
  providers: [ImageUrlPipe],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({opacity: 0}),
          animate('300ms', style({opacity: 1}))
        ]),
        transition(':leave', [
          style({ opacity: 1}),
          animate('300ms', style({opacity: 0}))
        ])
      ]
    )
  ]
})
export class ImageCropperComponent implements OnInit, OnDestroy {
  @Input() imageUrl: string = null;
  @Input() position: CropperPositionRotate = null;

  @Output() onClose: EventEmitter<CropperPositionRotate | null> = new EventEmitter();  
  fullImageUrl: string = null;
  cropper: CropperPosition = { x1: 0, y1: 0, x2: 0, y2: 0};
  lastPosition: CropperPositionRotate = {
    position: { x1: 0, y1: 0, x2: 0, y2: 0}, 
    angle: 0,
    width: 0,
    height: 0
  };
  canvasRotation: number = 0;
  visible: boolean = true;
  private loaded: boolean = false;


  constructor(private imageUrlPipe: ImageUrlPipe) {}

  ngOnInit() {
    document.getElementsByTagName("body")[0].classList.add("photoslider");
    if(this.imageUrl)
      this.fullImageUrl = this.imageUrlPipe.transform(this.imageUrl);
  }

  ngOnDestroy(): void {
    document.getElementsByTagName("body")[0].classList.remove("photoslider");
  }
    
  imageChangedEvent: any = '';
  croppedImage: any = '';

  isOldPosition(position: CropperPosition): boolean{
    return this.lastPosition.position.x1 == position.x1 &&
      this.lastPosition.position.x2 == position.x2 && 
      this.lastPosition.position.y1 == position.y1 && 
      this.lastPosition.position.y2 == position.y2; 
  }

  fileChangeEvent(event: any): void {
      this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    if(this.isOldPosition(event.imagePosition))
      return;
    Object.assign(this.lastPosition.position, event.imagePosition);
  }
  
  imageLoaded(image: LoadedImage) {
      setTimeout(() => {
        if(this.position){
          this.cropper = this.position.position;
          Object.assign(this.lastPosition , this.position);
          this.updateRotation();
        }
        this.lastPosition.width = image.original.size.width;
        this.lastPosition.height = image.original.size.height;
        this.loaded = true;
      }, 2);
  }


  cropperReady() {

  }
  loadImageFailed() {

  }

  close(setPostion: boolean) {
    this.visible = false;
    setTimeout(() => {
      this.onClose.emit(setPostion ? this.lastPosition : null);
    }, 300);
  }

  rotate(angel: number, event: any){
    this.lastPosition.angle += angel;
    if(this.lastPosition.angle == 360)
      this.lastPosition.angle = 0;
    if(this.lastPosition.angle < 0)
      this.lastPosition.angle = 360 + this.lastPosition.angle;
    this.updateRotation();
    //event.preventDefault();
  }

  updateRotation(){
    this.canvasRotation = this.lastPosition.angle / 90;
  }
}

export class CropperPositionRotate{
  public position: CropperPosition;
  public angle: number = 0;
  public width: number;
  public height: number;
}