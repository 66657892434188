
import { Request } from './request';

export class ChatRequest extends Request {
    public idHash: string;
    public lastMessageId: number;

    constructor(id_hash: string, last_message_id: number = 0){
        super();
        this.idHash = id_hash;
        this.lastMessageId = last_message_id;
    }
}
