import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppState } from 'src/app-state';
import { WebapiService } from './services/webapi.service';
//import { Geolocation } from '@capacitor/geolocation';
import {GeolocationService} from '@ng-web-apis/geolocation';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppNotification } from 'src/app-notification';
import { Lang } from './model/lang';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
  public translationLoaded: boolean = false;
  private geoSubscription: Subscription = null;
  public debugMessage: string = "";
  private watchId: number;

  constructor(private webApi: WebapiService, private readonly geolocation$: GeolocationService, private http: HttpClient) {
    this.getLang();
    AppState.enableGeoPosition.subscribe(val => {
      if(val)
        this.getPosition();
      else 
        this.disableGetPosition();
    });
    AppState.debugMessage.subscribe(val => {
      this.debugMessage = val;
    });

    /*
    Geolocation.requestPermissions({permissions: ["location"]}).then(status => {
      const printCurrentPosition = async () => {
        const coordinates = await Geolocation.getCurrentPosition();
        console.log('Current position:', coordinates);
      };
    });
    */
    AppState.enableGeoPosition.next(true);
  }
  ngOnInit(): void {
    if(AppState.isLogged()){
      this.webApi.getProfile(AppState.loggedUser.id_hash).subscribe(resp => {
        AppState.minimal_allowed_age.next(resp.data.minimal_allowed_age * 1);
        AppState.updateLoggedUser(resp.data.user);
      });
    }
  }

  ngOnDestroy(): void {
    this.disableGetPosition();
  }

  disableGetPosition(){
    if(this.geoSubscription == null)
      return;
    this.geoSubscription.unsubscribe();
    this.geoSubscription = null;
  }

  getPosition() {
    if(this.geoSubscription != null)
      return;
    this.geoSubscription = this.geolocation$.subscribe(position => 
     {
      AppState.geoPosition.next({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      });
      if(position.coords.latitude != 0 && position.coords.longitude != 0 && AppState.countryCode.getValue() == ""){
        this.http.get("https://nominatim.openstreetmap.org/reverse?format=json&lat=" +  position.coords.latitude + "&lon=" +  position.coords.longitude).subscribe((val: any) => {
          if(val.error){
            console.log(val.error);
            return;
          }
          if(val.address && val.address.country_code)
            AppState.countryCode.next(val.address.country_code);
        });
      }
    });
  }

  public getLang(){
    if(window.navigator.language){
      const lang_id = localStorage.getItem("lang");
      if(lang_id == null){
        const shortcut = window.navigator.language.toLocaleLowerCase().split("-")[0];
        if(AppState.Langs.value.has(shortcut))
          AppState.lang.next(AppState.Langs.value.get(shortcut).id);
      } else {
        AppState.Langs.value.forEach(l => {
          if(l.id == lang_id)
            AppState.lang.next(l.id);
        })
      }
    }
    AppState.lang.subscribe(l => {
      this.translationLoaded = false;
      this.webApi.getTranslations(l).subscribe(val => {
        if(val.translations){
          AppState.translations.next(val.translations);
          if(val.languages){
            const langs = new Map<string, Lang>();
            val.languages.forEach(lang => {
              langs.set(lang.shortcut, {id: lang.id, name: lang.name});
            });
            AppState.Langs.next(langs);
          }
        }
        localStorage.setItem("lang", l);
        this.translationLoaded = true;
      });
    })
  }

  public bodyClick(event: any){
    AppState.bodyClick.next(event.target as HTMLElement);
  }
}
