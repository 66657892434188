import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from '../components/loader/loader.component';
import { ImageUrlPipe } from '../pipes/image-url.pipe';
import { DistancePipe } from '../pipes/distance.pipe';
import { GenderClassnamePipe } from '../pipes/gender-classname.pipe';
import { TranslatePipe } from '../pipes/translate.pipe';
import { OnlyNumbersDirective } from '../directives/only-numbers.directive';
import { AutoFocusSelectDirective } from '../directives/auto-focus-select.directive';
import { DatePipe } from '../pipes/date.pipe';
import { TimePipe } from '../pipes/time.pipe';
import { ZodiacPipe } from '../pipes/zodiac.pipe';
import { SnippetPipe } from '../pipes/snippet.pipe';
import { CountyCodeSelectComponent } from '../components/county-code-select/county-code-select.component';
import { PhotoSliderComponentComponent } from '../components/photo-slider-component/photo-slider-component.component';
import { SwiperModule } from 'swiper/angular';
import { SwipeDirective } from '../directives/swipe.directive';
import { PinchZoomComponent } from '../components/pinch-zoom/pinch-zoom.component';
import { SupportComponent } from '../tabs/support/support.component';
import { ImageCropperComponent } from '../components/image-cropper/image-cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMapComponent } from '../components/leaflet-map/leaflet-map.component';
import { InviteFriendsComponent } from '../tabs/invite-friends/invite-friends.component';
import { WeightPipe } from '../pipes/weight.pipe';
import { HeightPipe } from '../pipes/height.pipe';
import { LocationPickerComponent } from '../components/location-picker/location-picker.component';
import { FormsModule } from '@angular/forms';
import { VerifyEmailComponent } from '../components/verify-email/verify-email.component';
import { MatchGameComponent } from '../tabs/match-game/match-game.component';
import { MapComponent } from '../tabs/map/map.component';
import { DateSelectComponent } from '../components/date-select/date-select.component';
import { FinishRegistrationProfileComponent } from '../home/finish-registration-profile/finish-registration-profile.component';
import { TermsAndConditionsComponent } from '../tabs/terms-and-conditions/terms-and-conditions.component';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    SwiperModule,
    IonicModule,
    FormsModule,
    ImageCropperModule,
    LeafletModule,
    RouterModule.forChild([])
  ],
  declarations: [
    LoaderComponent,
    CountyCodeSelectComponent,
    ImageUrlPipe, 
    DistancePipe, 
    GenderClassnamePipe,
    TranslatePipe,
    WeightPipe,
    HeightPipe,
    SnippetPipe,
    DatePipe,
    TimePipe,
    ZodiacPipe,
    OnlyNumbersDirective,
    AutoFocusSelectDirective,
    PhotoSliderComponentComponent,
    SwipeDirective,
    PinchZoomComponent,
    SupportComponent,
    ImageCropperComponent,
    LeafletMapComponent,
    InviteFriendsComponent,
    LocationPickerComponent,
    VerifyEmailComponent,
    MatchGameComponent,
    MapComponent,
    DateSelectComponent,
    FinishRegistrationProfileComponent,
    TermsAndConditionsComponent
  ],
  exports: [
    LoaderComponent,
    CountyCodeSelectComponent,
    ImageUrlPipe, 
    DistancePipe, 
    GenderClassnamePipe,
    TranslatePipe,
    WeightPipe,
    HeightPipe,
    SnippetPipe,
    DatePipe,
    TimePipe,
    ZodiacPipe,
    OnlyNumbersDirective,
    AutoFocusSelectDirective,
    PhotoSliderComponentComponent,
    SwipeDirective,
    PinchZoomComponent,
    SupportComponent,
    ImageCropperComponent,
    LeafletMapComponent,
    InviteFriendsComponent,
    LocationPickerComponent,
    VerifyEmailComponent,
    MatchGameComponent,
    MapComponent,
    DateSelectComponent,
    FinishRegistrationProfileComponent,
    TermsAndConditionsComponent
  ]
})
export class SharedModule { }
