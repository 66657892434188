import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Lang } from 'src/app/model/lang';
import { SocketService } from 'src/app/services/socket.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {
  public loading : boolean = true;
  public langs : Array<Lang> = [];
  
  constructor(private router: Router, private route: ActivatedRoute, private socketService: SocketService) { }

  ngOnInit() {
    const hash = this.route.snapshot.paramMap.get('hash');
    if(!hash || hash.length == 0)
      return this.goToHomePage();
    this.socketService.verifyEmail(hash).subscribe(val => {
      this.loading = false;
      //setTimeout(() => {
      //  this.goToHomePage();
      //}, 3000);
    });
  }

  goToHomePage() {
    this.router.navigate(["/"]);
  }
}
