import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  { 
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then( m => m.TabsModule)
  },
  {
    path: 'verify-email/:hash',
    component: VerifyEmailComponent
  }
  /*
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  */
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
