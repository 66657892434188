import { User } from 'src/app/model/user';
import { Response } from './response';

export class LoginResponse extends Response {
    public data: {
        user: User
        deviceHash: string,
        minimal_allowed_age: number
    }
    public hash: string;
}
