
import { AppState } from 'src/app-state';
import { Request } from './request';

export class DeviceSerializedData {
    oscpu = (navigator as any).oscpu ? (navigator as any).oscpu : "unknown";
    userAgent = navigator.userAgent;
    language = navigator.language;
    devicePixelRatio = window.devicePixelRatio;
    windowInnerWidth = window.innerWidth;
    windosInnerHeight = window.innerHeight;
    screenWidth = screen.width;
    screenHeight = screen.height;
    version = AppState.version;
};

export class LoginRequest extends Request {
    public username: string;
    public password: string;
    public serialized_data = new DeviceSerializedData();

    constructor(username: string, password: string){
        super();
        this.username = username;
        this.password = password;
    }
}
