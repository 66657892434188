import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AppState } from 'src/app-state';
import { WebapiService } from 'src/app/services/webapi.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {

  pageHtml: string = null;
  whiteLoader: boolean = true;
  constructor(private api: WebapiService) { }


  ngOnInit() {
    this.whiteLoader = !AppState.isLogged();
    this.api.getTermsAndCoditions().subscribe(html => {
      this.pageHtml = html;
    });
  }

}
