import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'distance'
})
export class DistancePipe implements PipeTransform {

  transform(value: number): any {
    return DistancePipe.get(value);
  }

  public static get(value: number){
    if (value < 1000)
      return Math.round(value) + "m";
    return (Math.round(value / 100) / 10) + " km";
  }
}
