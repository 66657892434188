import { Component, Input, OnInit } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateY(-100%)', opacity: 0}),
          animate('0ms', style({transform: 'translateY(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateY(0)', opacity: 1}),
          animate('0ms', style({transform: 'translateY(100%)', opacity: 0}))
        ])
      ]
    )
  ],
})
export class LoaderComponent implements OnInit {

  @Input() overlay: boolean = false;
  @Input() showLogo: boolean = false;
  @Input() white: boolean = false;
  @Input() fontSize: string = "";
  @Input() class: string = "";

  public style : any = {};
  
  constructor() { }

  ngOnInit() {
    const st = {} as any;
    if(this.fontSize != "")
      st.fontSize =  this.fontSize;
    this.style = st;
    if(this.white)
      this.class = "white";
  }

}
