
import { CropperPositionRotate } from 'src/app/components/image-cropper/image-cropper.component';
import { Request } from './request';

export class CropImageRequest extends Request {
    public data: CropperPositionRotate;
    public id_image: number;
    constructor(data: CropperPositionRotate, id: number){
        super();
        this.data = data;
        this.id_image = id;
    }
}
