import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { AppState } from 'src/app-state';
import { DialCode } from 'src/app/services/socket/dial-codes-response';
import { WebapiService } from 'src/app/services/webapi.service';

@Component({
  selector: 'app-county-code-select',
  templateUrl: './county-code-select.component.html',
  styleUrls: ['./county-code-select.component.scss']
})
export class CountyCodeSelectComponent implements OnInit, OnDestroy {

  private dialCodes: Array<DialCode> = [];
  public dialCodesChoices: Array<DialCode> = [];
  public selectedDialCode: DialCode = null;
  public opened: boolean = false;
  public loadingDialCodeByGeo: boolean | null = null;
  private bodyClick: Subscription = null;

  @Output() dialCodeEmiter = new EventEmitter<DialCode>();
  @Input() preselectedDialCodeId : number = -1;

  constructor(private http: HttpClient, private webApi: WebapiService) {
  }

  ngOnInit() {
    this.loadingDialCodeByGeo = true;
    this.webApi.getDialCodes().subscribe(resp => {
      this.dialCodes = resp.data;
      const setDialCode = this.preselectedDialCodeId > -1 ? this.preselectedDialCodeId : this.dialCodes[0].id;
      this.selectedDialCode = this.dialCodes.find(d => d.id == setDialCode);
      this.dialCodesChoices = this.dialCodes;
      this.dialCodeEmiter.emit(this.selectedDialCode);
      var flags = "";
      this.dialCodes.forEach(c => {
        flags += c.flag + "\n";
      });
      
      const findCode = AppState.countryCode.subscribe(countryCode => {
        if(countryCode == "")
          return;
          for(var i in this.dialCodes){
            if(this.dialCodes[i].country_code == countryCode){
              this.select(this.dialCodes[i], null);
              break;
            }
          }
        setTimeout(() => {
          findCode.unsubscribe();
        }, 1);
      });
      this.loadingDialCodeByGeo = false;
    });

    this.bodyClick = AppState.bodyClick
    .subscribe(elem => {
      if(elem.closest("app-county-code-select") == null)
        this.opened = false;
    });
  }

  ngOnDestroy(): void {
    this.bodyClick.unsubscribe();
  }

  open(){
    this.opened = !this.opened;
    if(this.opened){
      setTimeout(() => {
        (document.querySelector(".selectDialCodesSearch input") as any).focus();
      }, 10);
    }
  }

  select(dialCode: any, el: HTMLInputElement){
    this.selectedDialCode = dialCode;
    this.dialCodeEmiter.emit(this.selectedDialCode);
    this.dialCodesChoices = this.dialCodes;
    this.opened = false;
    if(el)
      el.value = "";
  }

  search(el: HTMLInputElement){
    const val = el.value.trim().toLowerCase();
    if(val.length == 0)
    {
      this.dialCodesChoices = this.dialCodes;
    } else {
      this.dialCodesChoices = this.dialCodes.filter(t => 
        t.code.toLocaleLowerCase().indexOf(val) >= 0 ||
        t.name.toLocaleLowerCase().indexOf(val) >= 0
      );
    }
  }

}
