
import { Request } from './request';

export class ProfileRequest extends Request {
    public idHash: string;

    constructor(id_hash: string){
        super();
        this.idHash = id_hash;
    }
}


export class UpdateAllProfilesRequest extends Request {
    public updateProfile: boolean = true;
}
