import { User } from "src/app/model/user";
import { Response } from './response';

export class ProfileResponse extends Response {
    public data: ProfileResponseData = new ProfileResponseData();  
}

export class ProfileResponseImage{
    public id: number;
    public src: string;
}

export class ProfileResponseData
{
    public user: User = null;
    public photo_thumbs: Array<ProfileResponseImage> = [];
    public photos: Array<ProfileResponseImage> = [];
    public minimal_allowed_age: number = 0;
    public errors: object = {};
}

export class UserLocationResponse extends Response {
    public data: string = "";  
}