import { Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe } from './translate.pipe';

@Pipe({
  name: 'height'
})
export class HeightPipe extends TranslatePipe {
  transform(value: number): string {
    let sign = "";
    if(value < 120){
      sign = "< ";
      value = 120;
    }
    else if(value > 210){
      sign = "> ";
      value = 210;
    }

    const realFeet = ((value * 0.393700) / 12);
    const feet = Math.floor(realFeet);
    const inches = Math.round((realFeet - feet) * 12);

    let ret = sign + value + " cm / " + feet + " feet";
    if(inches > 0)
      ret += ", " + inches + " inches";
    return  ret;
  }

}
