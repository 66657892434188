import { Request } from './request';

export class CahngePasswordRequest extends Request {
    public old_password : string;
    public password : string;
    public password2 : string;

    constructor(old_password: string, password: string, password2: string){
        super();
        this.old_password = old_password;
        this.password = password;
        this.password2 = password2;
    }
}
