import { BehaviorSubject } from "rxjs";
import { MessageSocket } from "./app/services/socket/messages-response";
import { AppState } from "./app-state";
import { TranslatePipe } from "./app/pipes/translate.pipe";
import { DistancePipe } from "./app/pipes/distance.pipe";

export class AppNotification {
    public static enabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    
    public static isSupported(): boolean
    {
        return ('Notification' in window);
    }

    public static isDenied() : boolean
    {
        if (AppNotification.isSupported())
            return Notification.permission == "denied";
        return true;
    }

    public static initialize(){
        if(AppNotification.isDenied()) {
            AppNotification.enabled.next(false);
            return;
        }
        const storagePermission = localStorage.getItem("notification-enabled");
        if(storagePermission != null) {
            AppNotification.enabled.next(storagePermission == "true");
            return;
        }
    }


    public static isDefault() : boolean
    {
        if(localStorage.getItem("notification-enabled"))
            return false;
        if (AppNotification.isSupported())
            return Notification.permission == "default";
        return false;
    }

    public static isEnabled(): boolean{
        if(AppNotification.isDenied())
            return false;
        return AppNotification.enabled.value;
    }

    public static enable(){
        if(AppNotification.isDenied())
            return;
        this.requestPermission();
    }

    private static requestPermission()
    {
        Notification.requestPermission().then(permission => {
            AppNotification.setState(permission === "granted");
        });
    }

    private static setState(state: boolean)
    {
        localStorage.setItem("notification-enabled", state ? "true" : "false");
        AppNotification.enabled.next(state);
    }
    
    public static disable()
    {
        AppNotification.setState(false);
    }

    public static sendMessageNotification(message: MessageSocket) {
        if(!AppNotification.isEnabled()){
            const audio = new Audio('/assets/audio/message-notification.mp3');
            audio.play();
        } else {
            let img = AppState.serverUrl;
            if(typeof message.avatar  == "string")
                img += message.avatar ;
            else
                img += message.avatar.src;
            const title = message.firstname + " " + message.surname + " ( " + message.age + " " + TranslatePipe.get("years", "common") + ", " + DistancePipe.get(message.distance) + " )";
            const options = {
            body: message.text,
            icon: img
            /*,
            vibrate: [200, 100, 200],
            tag: 'my-notification-tag',
            actions: [
                {action: 'open', title: 'Otevřít'},
                {action: 'close', title: 'Zavřít'},
            ]*/
            };  
            const notification = new Notification(title, options);
            notification.onclick = () => {
                let url = window.location.origin + "/messages/" + message.id_user;
                if(location.href != url)
                    location.href = url;
            };
        }
      }
}