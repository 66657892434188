import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DefaultEventsMap } from '@socket.io/component-emitter';
import { io, Socket } from "socket.io-client";
import { Request } from './socket/request';
import { LoginRequest } from './socket/login-request';
import { LoginResponse } from './socket/login-response';
import { Response } from './socket/response';
import { FriendsResponse } from './socket/friends-response';
import { DashboardRequest } from './socket/dashboard-request';
import { DashboardResponse } from './socket/dashboard-response';
import { AppState } from '../../app-state';
import { Observable, Subject } from 'rxjs';
import { SocketSubject } from './socket/socket-subject';
import { ProfileRequest, UpdateAllProfilesRequest } from './socket/profile-request';
import { ProfileResponse, ProfileResponseData, UserLocationResponse } from './socket/profile-response';
import { MessageSocket, MessagesResponse } from './socket/messages-response';
import { ChatResponse } from './socket/chat-response';
import { ChatRequest } from './socket/chat-request';
import { MessageRequest } from './socket/message-request';
import { MessageResponse } from './socket/message-response';
import { SupportMessageRequest } from './socket/support-message-request';
import { CropperPositionRotate } from '../components/image-cropper/image-cropper.component';
import { CropImageRequest } from './socket/crop-image-request';
import { CropImageResponse } from './socket/crop-image-response';
import { DeleteImageRequest } from './socket/delete-image-request';
import { User } from '../model/user';
import { UpdateProfileRequest } from './socket/update-profile-request';
import { VerifyPhoneRequest } from './socket/verify-phone-request';
import { changePositionsRequest } from './socket/change-positions-request';
import { VerifyEmailRequest } from './socket/verify-email-request';
import { CahngePasswordRequest } from './socket/cahnge-password-request';
import { SpeakingResponse } from './socket/speaking-response';
import { AppNotification } from 'src/app-notification';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private readonly client: Socket<DefaultEventsMap, DefaultEventsMap>;
  private readonly responseSubjects =  new Map<string, SocketSubject<object>>();
  public static instance: SocketService;
  public static updateMyProfileSubject: Subject<ProfileResponseData> = new Subject<ProfileResponseData>();
  public static updateViewedMessagesSubject: Subject<string> = new Subject<string>();
  public intervalConnect: any = null;

  constructor(private router: Router) { 
    SocketService.instance = this;
    this.client = io(AppState.socketUrl, {reconnection: true});
    this.client.on("loginResponse", this.loginResponse);
    this.client.on("getFriendsResponse", this.getFriendsResponse);
    this.client.on("getObservableResponse", this.onlyObservableResponse);
    this.client.on("sendMessageResponse", this.sendMessageResponse);
    this.client.on("setCoins", this.setCoins);
    this.client.on("updateProfile", this.updateProfileFromServer);
    this.client.on("updateMessageCount", this.updateMessageCount);
    this.client.on("updateViewedMessages", this.updateViewedMessages);
    this.client.on("disconnect", () => {
      console.log("socket disconnect");
    });
    this.client.on("connect", () => {
      console.log("socket connect");
    });
    this.client.connect();
  }

  updateViewedMessages(response: {userHash: string}) {
    if(AppState.loggedUser){
      SocketService.updateViewedMessagesSubject.next(response.userHash);
    }
  }

  public getImageUrl(path: string) : string{
    return AppState.serverUrl + path;
  }

  public login(username: string, password: string){
    this.send("login", new LoginRequest(username, password), "loginResponse");
  }

  loginResponse(response: any) {
    const resp = SocketService.instance.getResponse(LoginResponse, response);
    if(resp.code == 200){
      localStorage.setItem("logged", "true");
      AppState.minimal_allowed_age.next(resp.data.minimal_allowed_age * 1);
      AppState.updateLoggedUser(response.user);
      SocketService.instance.afterLogin();
    }
  }

  setCoins(response: {coins: number}) {
    if(AppState.loggedUser){
      AppState.loggedUser.coins = response.coins
      AppState.updateLoggedUser(AppState.loggedUser);
    }
  }

  updateProfileFromServer(response: ProfileResponseData) {
    if(AppState.loggedUser){
      AppState.updateLoggedUser(response.user);
      SocketService.updateMyProfileSubject.next(response);
    }
  }


  getFriends(request: DashboardRequest) : Observable<FriendsResponse> {
    return this.send("getFriends", request , "getObservableResponse", FriendsResponse);
  }

  getProfile(id_hash: string) : Observable<ProfileResponse> {
    return this.send("getProfile", new ProfileRequest(id_hash) , "getObservableResponse", ProfileResponse);
  }

  getUserLocation(id_hash: string) : Observable<UserLocationResponse> {
    return this.send("getUserLocation", new ProfileRequest(id_hash) , "getObservableResponse", UserLocationResponse);
  }

  updateAllProfiles() : Observable<ProfileResponse> {
    return this.send("getProfile", new UpdateAllProfilesRequest(), "getObservableResponse", ProfileResponse);
  }

  viewedChat(id_hash: string) : Observable<Response> {
    return this.send("viewedChat", new ProfileRequest(id_hash) , "getObservableResponse", Response);
  }

  getMessages() : Observable<MessagesResponse> {
    return this.send("getMessages", new Request() , "getObservableResponse", MessagesResponse);
  }

  getChatMessages(id_hash: string, last_message_id: number) : Observable<ChatResponse> {
    return this.send("getChat", new ChatRequest(id_hash, last_message_id) , "getObservableResponse", ChatResponse);
  }

  sendSupportMessage(message: string, email: string) : Observable<Response> {
    return this.send("createFeedback", new SupportMessageRequest(message, email) , "getObservableResponse", Response);
  }

  sendMessage(message: string, id_hash: string): Observable<Response>
  {
    //this.client.emit("callMethod", "sendMessage", (new MessageRequest(id_user, message)).Serialize());
    return this.send("sendMessage", new MessageRequest(id_hash, message), "getObservableResponse", Response);
  }

  cropImage(id: number, data: CropperPositionRotate): Observable<CropImageResponse>
  {
    return this.send("cropImage", new CropImageRequest(data, id), "getObservableResponse", CropImageResponse);
  }
  
  verifyEmail(hash: string): Observable<Response>
  {
    return this.send("verifyEmail", new VerifyEmailRequest(hash), "getObservableResponse", Response);
  }

  getSpeaking(): Observable<SpeakingResponse> {
    return this.send("getSpeaking", new Request(), "getObservableResponse", SpeakingResponse);
  }

  sendMessageResponse(response: any) {
    const resp = Object.assign(new MessageResponse(), response);
    const messages = AppState.messages.getValue();
    const findUser = resp.idUserFrom == AppState.loggedUser.id_hash ? resp.idUserTo : resp.idUserFrom;
    const userInLeftChat = messages.filter(t => t.id_user == findUser); //podle me zbytecny t.id_user_from == findUser || t.id_user == findUser
    const filtredMessages = messages.filter(t => t.id_user != findUser); //t => t.id_user_from != findUser && t.id_user != findUser

    let addMessage : MessageSocket = resp.GetSocketMessage();

    if(userInLeftChat.length > 0){
      let tmpMessage = userInLeftChat[0];
      tmpMessage.text = addMessage.text;
      tmpMessage.newMessage = addMessage.newMessage;
      tmpMessage.id_user_from = addMessage.id_user_from;
      tmpMessage.created = addMessage.created;
      addMessage = tmpMessage
    }
    if(AppState.messageDetailIdUser == findUser){
      addMessage.newMessage = 0;
      const set = [resp.GetChatSocketMessage(), ...AppState.chatMessages.getValue()];
      AppState.chatMessages.next(set);
      if(addMessage.id_user_from != AppState.loggedUser.id_hash)
        SocketService.instance.viewedChat(addMessage.id_user_from);
      if(document.hidden && addMessage.id_user_from != AppState.loggedUser.id_hash)
        AppNotification.sendMessageNotification(addMessage);
    } else {
      if(addMessage.id_user_from != AppState.loggedUser.id_hash)
        AppNotification.sendMessageNotification(addMessage);
      AppState.newMessagesCount.next(resp.msg_count);
    }    
    AppState.messages.next([addMessage, ...filtredMessages]);
  }

  updateMessageCount(resp: {count: number}) {
    AppState.newMessagesCount.next(resp.count);
  }

  getFriendsResponse(response: any){
    const resp = SocketService.instance.getResponse(FriendsResponse, response);
    if(resp.code == 200)
      AppState.friends.next(resp.data);
  }

  getDashboardData(request: DashboardRequest): Observable<DashboardResponse> 
  {
      return this.send("getUsers", request, "getObservableResponse", DashboardResponse);
  }

  addFriend(id_hash: string): Observable<Response> 
  {
      return this.send("addFriend", new ProfileRequest(id_hash), "getObservableResponse", Response);
  }

  removeFriend(id_hash: string): Observable<Response> 
  {
      return this.send("removeFriend", new ProfileRequest(id_hash), "getObservableResponse", Response);
  }

  deletePhoto(id_image: number, id_hash: string): Observable<ProfileResponse> 
  {
      return this.send("deleteImage", new DeleteImageRequest(id_image, id_hash), "getObservableResponse", ProfileResponse);
  }

  updateProfile(user: User): Observable<ProfileResponse> 
  {
    return this.send("updateProfile", new UpdateProfileRequest(user), "getObservableResponse", ProfileResponse);
  }

  changePositions(photos: Array<number>): Observable<ProfileResponse> 
  {
    return this.send("changePositions", new changePositionsRequest(photos), "getObservableResponse", ProfileResponse);
  }


  finishReqistration() : Observable<Response> 
  {
    return this.send("finishRegistration", new Request(), "getObservableResponse", Response);
  }

  changePassword(oldPass: string, newPass: string, newPass2: string) : Observable<Response> 
  {
    return this.send("changePassword", new CahngePasswordRequest(oldPass, newPass, newPass2), "getObservableResponse", Response);
  }  

  afterLogin(){
    this.router.navigate(['/tabs']);
  }

  public logout(){
    localStorage.setItem("logged", "false");
    this.client.disconnect();
    //this.router.navigateByUrl("/");
    window.location.href = window.location.origin;
  }

  private onlyObservableResponse(response: any){
    SocketService.instance.getResponse(Response, response);
  }

  public verifyPhone(id_hash: string, code: string, invitation: string | null) : Observable<LoginResponse>
  {
    const request = new VerifyPhoneRequest();
    request.idHash = id_hash;
    request.pin = code;
    request.invitation = invitation == null ? "" : invitation;
    return this.send<LoginResponse>("verifyPhone", request , "getObservableResponse", LoginResponse);
  } 
  
  private getResponse<T extends Response>(cls: new () => T, response: string): T{
    const resp = JSON.parse(response);
    const ret : T = Object.assign(new cls(), resp);

    let subject : SocketSubject<object> = null;
    if(this.responseSubjects.has(resp.responseHash)){
      subject = this.responseSubjects.get(resp.responseHash);
      this.responseSubjects.delete(resp.responseHash);
    }
    console.log("Socket response (" + resp.responseHash + ")");
    console.log(resp);
    if (ret.code == 401) // not logged
    {
      SocketService.instance.logout();
      return;
    }
    if(subject){
      let retSubject = ret;
      if(subject.responseObject){
        retSubject = Object.assign(subject.responseObject, resp);
      }
      subject.next(retSubject);
    }
    AppState.newMessagesCount.next(ret.msg_count);
    return ret;
  }

  send<T extends Response>(action: string, request: Request, responseMethod: string | null = null, cls: new () => T = null, socketIoMethod: string = "callMethod") : SocketSubject<T>{    
    console.log("Socket request (" + request.responseHash + ")");
    console.log(request);
    const ret = cls ? new SocketSubject<T>(new cls()) : new SocketSubject<T>(null);
    this.responseSubjects.set(request.responseHash, ret);

    if(responseMethod != null)
      this.client.emit(socketIoMethod, action, request.Serialize(), responseMethod);
    else 
      this.client.emit(socketIoMethod, action, request.Serialize());
    return ret;
  }

}
