import { Component, OnDestroy, OnInit } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app-state';

@Component({
  selector: 'app-invite-friends',
  templateUrl: './invite-friends.component.html',
  styleUrls: ['./invite-friends.component.css']
})
export class InviteFriendsComponent implements OnInit, OnDestroy {
  private destroyInter = new Subject<boolean>();
  public linkToShare: string = "";
  public textToShare: string = "";
  public titleToShare: string = "MeetRush.com";
  public isNativeShareUrl: boolean = false;
  public copied: boolean = false;
  
  constructor() { }

  ngOnInit() {
    const inter = interval(200);
    inter.pipe(takeUntil(this.destroyInter)).subscribe(() => {
      if(AppState.loggedUser != null){
        this.linkToShare = "https://meetrush.com/sign-up/?h=" + AppState.loggedUser.id_hash;
        this.textToShare = "Pojd se taky zaregistrovat " + this.linkToShare;
        this.destroyInter.next(true);
      }
    });
    this.isNativeShareUrl = typeof navigator.share != "undefined";
    this.addSkypeScript();
  }

  ngOnDestroy(): void {
    this.destroyInter.next(true);
    this.removeSkypeScript();
  }

  shareUrl(url: string){
    url = url.replace("[[textToShare]]", this.textToShare);
    url = url.replace("[[urlToShare]]", this.linkToShare);
    location.href = url;
  }

  shareOnTwitter(){
    const navUrl = '  https://twitter.com/intent/tweet?source=tweetbutton&text=' + this.textToShare + '&url=' + this.linkToShare;
    window.open(navUrl , '_blank');
  }

  showNotSupportedError(text){
    console.log(text);
  }

  shareOnFacebook(){
    const navUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + this.linkToShare;
    window.open(navUrl , '_blank');
  }

  async nativeShare(){
    const shareData = {
      title: this.titleToShare,
      text: this.textToShare
    }
    if(typeof navigator.share == "undefined")
    {
      this.showNotSupportedError("Browser not support native share");
      //AppState.debugMessage.next("Browser not support native share")
      return;
    }
    try {
      await navigator.share(shareData);
    } catch (err) {
      this.showNotSupportedError(err);
    }
  }

  shareSkype(){
    const els = document.getElementsByClassName("skypeShare");
    if(els.length > 0)
      (els[0] as any).click();
  }

  addSkypeScript(){
    this.removeSkypeScript();
    let r = window as any;
    r.loadSkypeWebSdkAsync = r.loadSkypeWebSdkAsync || function(p) {
        var js, sjs = document.getElementsByTagName("script")[0];
        js = document.createElement("script");
        js.id = p.id;
        js.src = p.scriptToLoad;
        js.onload = p.callback
        sjs.parentNode.insertBefore(js, sjs);
    };
    r.loadSkypeWebSdkAsync( {
        scriptToLoad: 'https://swx.cdn.skype.com/shared/v/latest/skypewebsdk.js',
        id: 'skype_web_sdk'
    });
  }

  removeSkypeScript(){
    const beforeEl = document.getElementById('skype_web_sdk');
    if(beforeEl)
      beforeEl.remove();
  }

  copyToClipboard(){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.linkToShare;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.copied = true;
    setTimeout(() => {
      this.copied = false;
    }, 3000);
  }
}
